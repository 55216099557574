<header class="app-header navbar">
  <div class="nav navbar-nav" [ngClass]="applicationImage?'h-100':''">
    <button class="navbar-toggler d-md-none" type="button" appMobileSidebarToggler>
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="nav-item nav-link sis-brand px-3 d-block d-sm-none h-100" href="#"><img src="{{applicationImage}}"
        class="sis-brand-logo mr-2 h-100" *ngIf="applicationImage" /><span class="sis-brand-bold">SIS</span></a>
    <a class="nav-item nav-link sis-brand pl-2 d-none d-sm-block h-100" href="#">
      <img src="{{applicationImage}}" class="sis-brand-logo mr-2 h-100" *ngIf="applicationImage"  alt=""/>
      <span class="h-100 align-middle">{{applicationTitle}}</span>
    </a>
  </div>

  <ul class="nav navbar-nav d-sm-down-none ml-auto">
    <!--User dropdown menu-->
    <li class="nav-item">
      <a class="nav-link mr-2" [routerLink]="['/home', 'help']" [translate]="'Help'"></a>
    </li>
    <li *ngIf="user" class="nav-item dropdown d-none d-md-block mr-2" dropdown placement="bottom right">
      <a class="nav-link" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="noop()">
        <span class="d-none d-lg-block">{{user.name}}<i class="ml-2 fa fa-angle-down"></i></span>
        <span class="d-block d-lg-none"><i class="ml-2 fa fa-user"></i><i class="ml-2 fa fa-angle-down"></i></span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item disabled" routerLinkActive="active" [routerLink]="['/profile']">
          <span [translate]="'Profile'"></span>
        </a>
        <div class="divider"></div>
        <a class="dropdown-item" [routerLink]="['/auth/logout']"><span [translate]="'Logout'"></span></a>
      </div>
    </li>

    <!--Language switch dropdown menu-->
    <ng-container *ngIf="languages && languages.length > 1">
      <li *ngIf="currentLang" class="nav-item dropdown d-none d-md-block" dropdown placement="bottom right">
        <a class="nav-link" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false"
          dropdownToggle>
          {{currentLang}}<i class="ml-2 fa fa-angle-down"></i>
        </a>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
          <li role="menuitem" *ngFor="let language of languages">
            <button class="dropdown-item" [disabled]="language===currentLang" type="button"
              (click)="changeLanguage(language)">{{ "Languages." + language | translate:{id: language} }}</button>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
</header>

<div class="app-body">
  <app-sidebar #appSidebar [display]="'lg'">
    <app-sidebar-nav #appSidebarNav></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">

    <div class="container-fluid pt-4">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>

<footer class="app-footer d-none">
  <a href="https://universis.io"></a> &copy; 2020 Universis Project
  <span class="float-right"></span>
</footer>
