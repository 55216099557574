import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// LOCALES: import extra locales here
import en from '@angular/common/locales/en';
import el from '@angular/common/locales/el';
import { THIS_APP_LOCATIONS } from './app.locations';
import { APP_SIDEBAR_LOCATIONS } from './app.sidebar.locations';
import {IndexComponent} from './layouts/index.component';
import {CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {
  APP_LOCATIONS, AppSidebarService,
  AuthModule,
  ConfigurationService,
  ErrorModule, LocalUserStorageService,
  SharedModule,
  SIDEBAR_LOCATIONS,
  UserStorageService
} from '@universis/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {AdvancedFormsModule} from '@universis/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AppSidebarModule} from '@coreui/angular';
import {AngularDataContext, DATA_CONTEXT_CONFIG} from '@themost/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    SharedModule.forRoot(),
    RouterModule,
    AuthModule,
    FormsModule,
    AppRoutingModule,
    AppSidebarModule,
    ErrorModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    AdvancedFormsModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot()
  ],
  providers: [
    Title,
    {
      provide: DATA_CONTEXT_CONFIG, useValue: {
        base: '/',
        options: {
          useMediaTypeExtensions: false,
          useResponseConversion: true
        }
      }
    },
    {
      provide: APP_LOCATIONS,
      useValue: THIS_APP_LOCATIONS
    },
    {
      provide: SIDEBAR_LOCATIONS,
      useValue: APP_SIDEBAR_LOCATIONS
    },
    AngularDataContext,
    {
      provide: APP_INITIALIZER,
      // use APP_INITIALIZER to load application configuration
      useFactory: (configurationService: ConfigurationService) =>
        () => {
          // load application configuration
          return configurationService.load().then(() => {
            // LOCALES: register application locales here
            registerLocaleData(en);
            registerLocaleData(el);
            // return true for APP_INITIALIZER
            return Promise.resolve(true);
          });
        },
      deps: [ ConfigurationService ],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: (configurationService: ConfigurationService) => {
        return configurationService.currentLocale;
      },
      deps: [ConfigurationService]
    },
    // use hash location strategy
    // https://angular.io/api/common/HashLocationStrategy
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: UserStorageService,
      useClass: LocalUserStorageService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translateService: TranslateService, private sidebarService: AppSidebarService) {
    const sources = environment.languages.map(async (language) => {
      return import(`../assets/i18n/${language}.json`).then((translations) => {
        this.translateService.setTranslation(language, translations, true);
      });
    });
    // execute chain
    Promise.all(sources).then(() => {
      this.translateService.onDefaultLangChange.subscribe(() => {
        this.sidebarService.loadConfig().then(r => {});
      });
    }).catch((err) => {
      console.error('An error occurred while loading application translations');
      console.error(err);
    });
  }
}
