export const THIS_APP_LOCATIONS = [
  {
    privilege: 'Location',
    target: {
        url: '^/auth/'
    },
    mask: 1
  },
  {
    privilege: 'Location',
    target: {
        url: '^/error'
    },
    mask: 1
  },
  {
    privilege: 'Location',
    account: {
        name: 'Students'
    },
    target: {
        url: '^/'
    },
    mask: 1
  },
  {
    privilege: 'Location',
    account: {
        name: 'Candidates'
    },
    target: {
        url: '^/'
    },
    mask: 1
  }
];
