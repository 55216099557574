export const APP_SIDEBAR_LOCATIONS = [
  {
    name: 'Home',
    key: 'Sidebar.Dashboard',
    url: '/',
    icon: 'fa fa-archive',
    index: 0
  },
  {
    name: 'Help',
    class: 'd-md-none d-sm-block',
    key: 'Sidebar.Help',
    url: '/home/help',
    icon: 'fa fa-question',
    index: 0
  }
];
