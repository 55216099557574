import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {ConfigurationService, UserService, AppSidebarService} from '@universis/common';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styles: [`
  :host {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
  }
  `]
})
export class IndexComponent implements OnInit {

  constructor(private _userService: UserService,
              private _configurationService: ConfigurationService,
              private _appSidebarService: AppSidebarService) { }

  @ViewChild('appSidebarNav', {
    static: true
  }) appSidebarNav: any;
  @ViewChild('appSidebar', {
    static: true
  }) appSidebar: any;
  @Input() user: any;
  public today = new Date();
  public languages: any;
  public currentLang: string;
  public applicationImage: string;
  public applicationTitle: string;

  async ngOnInit(): Promise<void> {
    // get sidebar navigation items
    this.appSidebarNav.navItemsArray = (this._appSidebarService as any).sidebarLocations;
    // get current user
    this.user = await this._userService.getUser();
    // get current language
    this.currentLang = this._configurationService.currentLocale;
    // get languages
    this.languages = this._configurationService.settings.i18n.locales;
    // get path of brand logo
    const appSettings: { image?: string; title?: string } = this._configurationService.settings.app;
    this.applicationImage = appSettings && appSettings.image;
    // get application title
    this.applicationTitle = (appSettings && appSettings.title) || 'Universis';

    // set minimized
    this.appSidebar.minimized = true;
    // and call method to minimize sidebar
    this.appSidebar.isMinimized(this.appSidebar.minimized);

  }

  changeLanguage(lang: any): void {
    this._configurationService.currentLocale = lang;
    // reload current route
    window.location.reload();
  }

  noop(): void {
    //
  }

}
